'use client';

import { useCurrencyContext } from '@contexts/currency-context';
import { CAD_CURRENCY, CURRENCY_COOKIE, USD_CURRENCY } from '@utils/currency';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@v2/ui';
import { getCookie, setCookie } from 'cookies-next';
import Image from 'next/image';
import { useEffect, useState } from 'react';

export const SelectCurrencyModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { setCurrency } = useCurrencyContext();

  useEffect(() => {
    const currencyCookie = getCookie(CURRENCY_COOKIE) as string;
    if (!currencyCookie) {
      setIsOpen(true);
    }
  }, []);

  const selectCurrency = (currency: string) => {
    setCookie(CURRENCY_COOKIE, currency);
    setCurrency(currency);

    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="gap-8">
        <DialogHeader>
          <DialogTitle
            className={'text-center text-3xl font-bold text-gray-800'}
          >
            Choose your preferred currency
          </DialogTitle>
        </DialogHeader>
        <div className="mx-auto w-full max-w-lg rounded-xl bg-white">
          <ul className="flex flex-col gap-y-3">
            <li>
              <button
                className="flex w-full items-center rounded-xl border border-gray-200 p-4 hover:bg-gray-200"
                onClick={() => selectCurrency(USD_CURRENCY)}
              >
                <Image
                  alt="usa flag"
                  src="/flags/usa.webp"
                  width={48}
                  height={48}
                  className="mr-4"
                />
                <div className="text-left">
                  <h2 className="text-base font-bold text-gray-800">
                    US dollar
                  </h2>
                  <p className="text-sm font-medium text-gray-500">USD</p>
                </div>
              </button>
            </li>
            <li>
              <button
                className="flex w-full items-center rounded-xl border border-gray-200 p-4 hover:bg-gray-200"
                onClick={() => {
                  selectCurrency(CAD_CURRENCY);
                }}
              >
                <Image
                  alt="canada flag"
                  src="/flags/canada.webp"
                  width={48}
                  height={48}
                  className="mr-4"
                />
                <div className="text-left">
                  <h2 className="text-base font-bold text-gray-800">
                    Canadian dollar
                  </h2>
                  <p className="text-sm font-medium text-gray-500">CAD</p>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};
